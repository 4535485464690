.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

.css-1t6c9ts {
    display: flex;
    flex-direction: row-reverse !important;
}

